import { cx } from 'linaria';

const createClassNames = (classes = [], signature = '') =>
  classes?.length > 0
    ? cx(
        ...classes.map(className =>
          className ? `${className}${signature}` : null
        )
      )
    : null;

export default createClassNames;
