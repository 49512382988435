import { css } from 'linaria';
import { withLayout } from '../Components/Layout';
import { theme } from '../../../Theme';

const customLayoutCSS = css`
  ${theme.below.md} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-column-gap: 0 !important;
    column-gap: 0 !important;
    justify-content: center !important;
    padding: 0;

    .layout-child {
      flex: 0 0 calc(50% - ${theme.space[2]});
      margin-top: 1px;
    }

    .component-card-1,
    .component-card-3 {
      padding-right: 3px;
    }

    .component-card-2,
    .component-card-4 {
      padding-left: 3px;
    }

    .component-card-3,
    .component-card-4 {
      margin-top: 3px;
    }

    .component-card-1,
    .component-card-2 {
      margin-bottom: 3px;
    }
  }
`;

const Cards = props => withLayout({ props, customLayoutCSS });

export default Cards;
