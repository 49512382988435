import React from 'react';
import { css, cx } from 'linaria';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import createClassNames from '../../Utils/createClassNames';
import { Below } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image/Image';

const HTMLcss = css`
  &-img {
    flex-grow: 1;
    max-height: 780px;
    overflow: hidden;
    margin: 0;

    @media screen and (min-width: 768px) {
      margin: 0 12px;
    }

    // > div {
    //   min-height: 240px;
    //   max-height: 400px;
    // }

    @media screen and (min-width: 1056px) {
      margin: 0 32px;

      // > div {
      //   min-height: 580px;
      //   max-height: 700px;
      // }
    }
  }

  &-img33 {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 33.3333%;
      margin: 0 0px;

      > div {
        min-height: 200px;
        max-height: 400px;
      }
    }
  }

  &-img50 {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: calc(50% - 64px);
    }

    [data-flight-image-container] {
      height: 100% !important;
    }

    [data-flight-image] img {
      position: static !important;
    }
  }
`;

const base = HTMLcss;
const ImageColumn = (props) => {
  const {
    className,
    count,
    desktopImage,
    phoneImage
  } = getPropsOrFieldValues(props);

  return (
    <div className={cx(
      createClassNames([base, className], `-img`),
      createClassNames([base, className], `-img${count >= 3 ? '33' : '50'}`)
    )}>
      <Below breakpoint='md'>
        {matches => (
          <Image
            cover
            className={cx(
              createClassNames([base, className], '-image')
            )}
            src={matches ? phoneImage ?? desktopImage : desktopImage}
            critical={true}
            sizes={[1, 1, 1, 1, 1280]}
          >
          </Image>
        )}
      </Below>
    </div>
  );
};

export default ImageColumn;
export { ImageColumn };
