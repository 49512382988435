import { css, cx } from 'linaria';
import createClassNames from './Utils/createClassNames';

export const fontSizeKeyMedium = 'medium';
export const fontSizeKeyLarge = 'large';

const fontSizeClassLarge = css`
  font-size: 1.25rem;
`;

export const themeSelectorFontSize = (
  fontSize = fontSizeKeyMedium,
  className = null
) => {
  let fontSizeClass = null;

  switch (fontSize) {
    case fontSizeKeyLarge:
      fontSizeClass = fontSizeClassLarge;
      break;

    default:
      fontSizeClass = null;
      break;
  }

  return cx(
    fontSizeClass ? fontSizeClass : null,
    createClassNames([className], `--${fontSize}`)
  );
};
