import React from 'react';
import { css, cx } from 'linaria';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import createClassNames from '../../Utils/createClassNames';
import Image from '@jetshop/ui/Image/Image';
import { Below } from '@jetshop/ui/Breakpoints';

const HTMLcss = css`
  &-wrapper {
    width: 100vw;
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 32px;
    
    @media screen and (min-width: 1056px) {
      margin-bottom: 64px;
    }
  }

  &-col {
    width: 100%;
    flex-grow: 1;

    > div[data-flight-image-container] {
      min-height: 240px;
      max-height: 550px;
      object-fit: cover;
      height: 100% !important;
    }

    @media screen and (min-width: 768px) {
      width: calc(50% - 64px);
      &:nth-child(1) {
        padding-right: 32px;
      }

      &:nth-child(2) {
        padding-left: 32px;
      }
    }
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
    max-width: 1088px;
    flex-direction: column-reverse;

    @media screen and (min-width: 768px) {
      width: calc(100% + 64px);
      margin: 0 auto;
      flex-direction: row;
    }
  }

  &-reverse {
    @media screen and (min-width: 768px) {
      width: calc(100% + 64px);
      margin: 0 auto;
      flex-direction: row-reverse;

      .col-media, .col-text {
        &:nth-child(1) {
          padding-left: 32px;
          padding-right: 0;
        }

        &:nth-child(2) {
          padding-left: 0;
          padding-right: 32px;
        }
      }
    }
  }

  &__text {
    padding: 32px 16px 0 16px;

    @media screen and (min-width: 768px) {
      padding: 0 16px 32px;
    }

    ul {
      list-style: inside;
      margin-bottom: 16px;
      
      li {
        margin-bottom: 8px;
      }
    }
  }

  &-image {
    img {
      object-fit: cover !important;
    }
  }
`;

const base = HTMLcss;
const MediaText = (props) => {
  const {
    className,
    text,
    desktopImage,
    phoneImage,
    mediaLeft,
  } = getPropsOrFieldValues(props);

  return (
    <div className={cx(
      createClassNames([base, className], '-wrapper'),
    )}>
      <div className={cx(
        createClassNames([base, className], '-row'),
        mediaLeft && createClassNames([base, className], '-reverse'),
      )}>
        <div className={cx(
          createClassNames([base, className], '-col'),
          'col-text'
        )}>
          {text && (
            <div
              className={cx(
                createClassNames([base, className], '__text')
              )}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </div>
        <div className={cx(
          createClassNames([base, className], '-col'),
          'col-media'
        )}>
          <Below breakpoint='md'>
            {matches => (
              <Image
                className={cx(
                  createClassNames([base, className], '-image')
                )}
                src={matches ? phoneImage ?? desktopImage : desktopImage}
                critical={true}
                sizes={[1, 1, 1, 1, 1280]}
              >
              </Image>
            )}
          </Below>
        </div>
      </div>
    </div>
  );
};

export default MediaText;
export { MediaText };
