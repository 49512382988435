import React from 'react';
import { css, cx } from 'linaria';
import t from '@jetshop/intl';
import { theme } from '../../../Theme';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import { TrendLink } from '../../../ui/Button';
import {
  fontSizeKeyMedium,
  themeSelectorFontSize
} from '../../ThemeClassSelectors';
import createClassNames from '../../Utils/createClassNames';

const HTMLcss = css`
  &__pre-title {
    font-size: 1rem;

    > * {
      font-weight: 400;
    }
  }

  &__html {
    h1,
    h2,
    h3,
    h4 {
      font-size: 1.625rem;
    }
  }

  &__button {
    display: inline-flex;
  }

  ${theme.above.md} {
    &__button-wrapper {
      margin-top: ${theme.space[3]};
    }
  }
`;

const base = HTMLcss;

const HTML = props => {
  const {
    children,
    className,
    preTitle,
    html,
    link,
    linkText,
    htmlFontSize = fontSizeKeyMedium,
    contentPosition = 'center',
    font,
    clickableImage = false
  } = getPropsOrFieldValues(props);

  if (!link && !preTitle && !html && !children) return null;

  return (
    <div
      className={cx(
        base,
        `${className}__content--${font}`,
        className ? `${className}__content` : null,
        className && contentPosition
          ? `${className}__content--${contentPosition}`
          : null
      )}
    >
      {preTitle && (
        <div
          className={createClassNames([base, className], '__pre-title')}
          dangerouslySetInnerHTML={{ __html: preTitle }}
        />
      )}
      {children}
      {html && (
        <div
          className={cx(
            createClassNames([base, className], '__html'),
            themeSelectorFontSize(htmlFontSize, className)
          )}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}
      {link && !clickableImage && (
        <div
          className={createClassNames([base, className], '__button-wrapper')}
        >
          <TrendLink
            className={cx(createClassNames([base, className], '__button'))}
            to={link}
          >
            {linkText ? linkText : t('Read more')}
          </TrendLink>
        </div>
      )}
    </div>
  );
};

export default HTML;
export { HTML };
