import React from 'react';
import { cx } from 'linaria';
import getPropsOrFieldValues, {
  checkPropValue
} from '../../Utils/getPropsOrFieldValues';
import {
  containerClass,
  marginClass,
  gapClass,
  layoutBase
} from '../../ThemeClasses';

const layoutComponentKey = 'component-layout';

export const Layout = ({
  children,
  className,
  container = null,
  margin = null,
  gap = null,
  fullwidthMobile = null,
  style = null
}) => {
  if (!container && !margin) {
    return <div className={className}>{children}</div>;
  }

  if (children?.length > 1) {
    gap = gap ? gap : 'none';
  }

  const layoutClasses = [
    {
      baseClass: containerClass,
      field: container,
      prefix: 'cc-'
    },
    {
      baseClass: marginClass,
      field: margin,
      prefix: 'mc-'
    },
    {
      baseClass: gapClass,
      field: gap,
      prefix: 'gc-'
    }
  ];

  const containerClasses = layoutClasses.reduce((acc, lc) => {
    const fieldValue = checkPropValue(lc.field);

    const subClass = fieldValue
      ? lc.prefix
        ? `${lc.prefix}${fieldValue}`
        : lc.field
      : null;

    return [subClass ? cx(lc.baseClass, subClass) : undefined, ...acc];
  }, []);

  return (
    <div
      className={cx(
        layoutBase,
        className,
        ...containerClasses,
        fullwidthMobile ? 'cc-fullwidth-mobile' : null,
        'layout-wrapper'
      )}
      style={style}
    >
      {gap
        ? children?.length >= 1
          ? children.map((child, i) => (
              <div className={cx('layout-child', child.key)} key={child.key}>
                {child}
              </div>
            ))
          : children
        : children}
    </div>
  );
};

export const spliceLayoutFromChildren = children => {
  const layoutIndex = children.findIndex(child =>
    child?.key.includes(layoutComponentKey)
  );

  children.splice(layoutIndex, 1);

  return children;
};

export const spliceLayout = props => {
  const validChildren = props?.children?.filter(Boolean);

  if (!validChildren?.length > 0) return [null, null];

  const layoutPropsData = props.children?.find(
    child => child?.key.indexOf(layoutComponentKey) !== -1
  )?.props;

  const children = spliceLayoutFromChildren(props.children);
  const layoutProps = getPropsOrFieldValues(layoutPropsData);

  return [children, layoutProps];
};

export const withLayout = ({ props, customLayoutCSS }) => {
  const [children, layoutProps] = spliceLayout(props);

  return (
    <Layout
      {...layoutProps}
      className={cx(
        children?.length > 1 ? `${layoutBase}--multi-children` : null,
        customLayoutCSS
      )}
    >
      {children}
    </Layout>
  );
};

export default Layout;
