import { css } from 'linaria';
import { theme } from '../Theme';

export const maxWidthBase = `
  width: 100%;
  max-width: 81.5rem;
  margin: 0 auto;
`;

export const maxWidth = css`
  ${maxWidthBase}
`;

export const edgePaddingBase = `
  padding-right: ${theme.space[4]};
  padding-left:  ${theme.space[4]};

  ${theme.below.md} {
    padding-right:  ${theme.space[2]};
    padding-left:  ${theme.space[2]};
  }
`;

export const edgePadding = css`
  ${edgePaddingBase}
`;

export const containerBase = `
  ${maxWidthBase}
  ${edgePaddingBase}
`;

export const container = css`
  ${containerBase}

  &.cc-xs {
    max-width: 38rem;
  }

  &.cc-s {
    max-width: 51rem;
  }

  &.cc-nomaxwidth {
    max-width: none;
    padding: 0;
  }

  &.cc-fullwidth {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: 100vw;
    max-width: 100vw;
    padding: 0;
  }

  &.mc-none {
    margin-bottom: 0rem !important;
  }

  &.mc-xs {
    margin-bottom: 1rem !important;
  }

  &.mc-s {
    margin-bottom: 1.5rem !important;
  }

  &.mc-m {
    margin-bottom: 2.5rem !important;
  }

  &.mc-l {
    margin-bottom: 3rem !important;
  }

  &.mc-xl {
    margin-bottom: 3.5rem !important;
  }

  &.ml-0 {
    margin-left: 0;
  }
  &.mr-0 {
    margin-right: 0;
  }
`;

export const containerClass = css`
  ${containerBase}

  &.cc-xxs {
    max-width: ${theme.breakpoints.sm};
  }

  &.cc-xs {
    max-width: ${theme.breakpoints.md};
  }

  &.cc-s {
    max-width: ${theme.breakpoints.lg};
  }

  &.cc-m {
    max-width: ${theme.breakpoints.xl};
    ${theme.above.xl} {
      padding: 0;
    }
  }

  &.cc-l {
    max-width: 84rem;
  }

  &.cc-fullwidth {
    max-width: none;
    padding: 0;
  }

  ${theme.below.md} {
    &.cc-fullwidth-mobile {
      max-width: none;
      padding: 0;
    }
  }
`;

export const gapClass = css`
  display: flex;

  &.gc-s {
    column-gap: ${theme.space[1]};
  }

  &.gc-m {
    column-gap: ${theme.space[2]};
  }

  &.gc-l {
    column-gap: ${theme.space[4]};
  }

  & > * {
    flex: 1 1 100%;
  }

  ${theme.below.md} {
    flex-direction: column;

    & > * + * {
      margin-top: ${theme.space[1]};
    }
  }
`;

export const layoutBase = css`
  ${theme.below.md} {
    &[class*='grid-'] {
      display: block;

      > div {
        margin-right: 0;
        margin-bottom: ${theme.space[2]};
      }
    }
  }
`;

export const marginClass = css`
  &.mc-s {
    margin-bottom: ${theme.space[1]};
  }

  &.mc-ms {
    margin-bottom: ${theme.space[2]};
  }

  &.mc-m {
    margin-bottom: ${theme.space[4]};
  }

  &.mc-l {
    margin-bottom: ${theme.space[6]};
  }

  &.mc-xl {
    margin-bottom: ${theme.space[8]};
  }

  ${theme.below.md} {
    &.mc-s {
      margin-bottom: ${theme.space[2]};
    }

    &.mc-m {
      margin-bottom: ${theme.space[3]};
    }

    &.mc-l {
      margin-bottom: ${theme.space[4]};
    }
    &.mc-xl {
      margin-bottom: ${theme.space[6]}t;
    }
  }
`;

export const flex = css`
  display: flex;

  &.fxw-w {
    flex-wrap: wrap;
  }

  &.fxd-c {
    flex-direction: column;
  }

  &.jc-sb {
    justify-content: space-between;
  }

  &.jc-c {
    justify-content: center;
  }

  &.ai-c {
    align-items: center;
  }
`;

export const heading = css`
  margin: 0 auto;
  text-align: center;

  * {
    font-weight: 600;
    line-height: 1.25;
  }

  & > * {
    margin-bottom: 0.5rem !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    max-width: 2.75rem;
    height: 1px;
    margin: 0.5rem auto 1.5rem auto;
    background: ${theme.colors.black};
  }

  a {
    text-decoration: none;
  }
`;

export const cardLinkCSS = css`
  display: inline-block;
  position: relative;
  padding-bottom: 11px;
  text-decoration: none;
  font-weight: 300;
  color: currentColor;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 100%;
    left: 0;
    background: currentColor;
    transition: height ease-in-out 0.2s;
    will-change: height;
  }

  &:hover {
    &:after {
      height: 2px;
    }
  }
`;

export const coverBase = `
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`;

export const cover = css`
  ${coverBase}
`;

export const imageLinkWrapper = css`
  position: relative;

  div[data-flight-image-children] {
    position: absolute !important;
    top: 0;
    left: 0;
  }
`;

export const sectionTitleCSS = css`
  margin-bottom: ${theme.space[3]};

  h1,
  h2,
  h3 {
    font-size: 1.25rem;
  }

  ${theme.above.md} {
    margin-bottom: ${theme.space[4]};

    h1,
    h2,
    h3 {
      font-size: 2rem;
    }
  }
`;
