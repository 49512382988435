import React from 'react';
import { css, cx } from 'linaria';
import Image from '@jetshop/ui/Image/Image';
import { Below } from '@jetshop/ui/Breakpoints';
import { getPropsOrFieldValues } from '../../Utils/getPropsOrFieldValues';
import { theme } from '../../../Theme';
import HTML from './HTML';
import { TrendLink } from '../../../ui/Button';

const threeImageBlocksCSS = css`
  display: flex;
  position: relative;
  color: ${theme.colors.white};
  padding: 0;

  &__content {
    width: 100%;
    position: absolute;
    text-transform: none;

    &--center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding: 0 ${theme.space[4]};
    }

    &--left {
      left: ${theme.space[2]};
      bottom: ${theme.space[3]};
      text-align: left;
    }
  }

  &__pre-title {
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  &__html {
    margin-top: 0.8rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  div[data-flight-image-children] {
    position: absolute !important;
  }

  ${theme.below.md} {
    &__html {
      h1,
      h2,
      h3,
      p {
        font-size: 1.625rem;
        font-weight: 500;
      }
    }
  }
`;

const base = threeImageBlocksCSS;

const MaybeLinkWrapper = ({ children, className, link, clickableImage }) => {
  return React.createElement(
    clickableImage && link ? TrendLink : 'div',
    {
      className,
      ...(clickableImage && link ? { to: link } : {})
    },
    children
  );
};

const ThreeImageBlocks = props => {
  const {
    imageDesktop,
    imageMobile,
    contentPosition = 'center',
    preTitle,
    html,
    link,
    linkText,
    clickableImage,
    critical,
    children,
    imageCaption
  } = getPropsOrFieldValues(props);

  if (!imageDesktop) return null;

  return (
    <div>
      <MaybeLinkWrapper
        link={link}
        clickableImage={clickableImage}
        className={cx(base, 'card')}
      >
        <Below breakpoint="md">
          {matches => (
            <Image
              cover
              src={matches ? imageMobile ?? imageDesktop : imageDesktop}
              critical={critical}
              sizes={[1, 1, 1, 1, 800]}
            >
              <>
                <HTML
                  className={base}
                  children={children}
                  preTitle={preTitle}
                  html={html}
                  link={link}
                  linkText={linkText}
                  clickableImage={clickableImage}
                  contentPosition={contentPosition}
                />
              </>
            </Image>
          )}
        </Below>
      </MaybeLinkWrapper>
      {imageCaption && (
        <div dangerouslySetInnerHTML={{ __html: imageCaption }} />
      )}
    </div>
  );
};

export default ThreeImageBlocks;
export { ThreeImageBlocks };
