import { css } from 'linaria';
import { theme } from '../../../Theme';
import { withLayout } from '../Components/Layout';

const customLayoutCSS = css`
  display: flex;
  width: 100%;
  flex-direction: column;

  .component-threeimageblocks-1 {
    [data-flight-image-container] {
      height: 550px !important;
    }
  }
  .component-threeimageblocks-2,
  .component-threeimageblocks-3 {
    [data-flight-image-container] {
      padding-bottom: 60% !important;
    }
  }

  ${theme.above.md} {
    display: grid;
    width: 100%;
    height: auto;

    grid-template-columns: 65% 35%;
    grid-template-rows: 50% 50%;
    grid-auto-flow: column;

    .component-threeimageblocks-1 {
      grid-column: 1;
      grid-row: 1 / 4;
      margin-right: ${theme.space[4]};

      > div {
        height: 100% !important;
      }

      .card {
        height: 100% !important;
      }

      [data-flight-image-container] {
        height: 100% !important;
        padding-bottom: 63.23529411764706% !important;
      }
    }

    .component-threeimageblocks-2 {
      grid-column: 2;
      grid-row: 1 / 2;
      margin-bottom: ${theme.space[2]};
      [data-flight-image-container] {
        padding-bottom: calc(63.2353% - 16px) !important;
      }
    }

    .component-threeimageblocks-3 {
      grid-column: 2;
      grid-row: 2 / 3;
      margin-top: ${theme.space[2]};

      [data-flight-image-container] {
        padding-bottom: calc(63.2353% - 16px) !important;
      }
    }
  }
`;

const ThreeImageBlocks = props => withLayout({ props, customLayoutCSS });

export default ThreeImageBlocks;
