import React from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'linaria';
import Image from '@jetshop/ui/Image/Image';
import t from '@jetshop/intl';
import { Below } from '@jetshop/ui/Breakpoints';
import { TrendLink } from '../../../ui/Button';
import { theme } from '../../../Theme';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';

export const verticalCardGridLayoutCSS = css`
  display: flex;
  flex-wrap: wrap !important;
  width: 100%;

  ${theme.below.md} {
    .maybe-link-wrapper {
      flex-direction: column-reverse !important;
    }

    .layout-child {
      padding: 0 !important;
    }
  }

  .layout-child {
    background: ${theme.colors.white};
    margin-top: ${theme.space[2]};
  }

  &.cc-m,
  &.cc-s,
  &.cc-l {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @media (min-width: 800px) and (max-width: 1250px) {
    .layout-child {
      max-height: 270px;
      padding: 0 !important;
      display: flex;
      margin-top: ${theme.space[2]};
    }

    .maybe-link-wrapper {
      flex-direction: row-reverse !important;
      width: 100%;
    }
  }

  ${theme.above.lgl} {
    &.gc-none .layout-child {
      flex: 0 0 50%;
    }

    &.gc-s .layout-child {
      flex: 0 0 calc(50% - ${theme.space[1]}) !important;
    }
    &.gc-m .layout-child {
      flex: 0 0 calc(50% - ${theme.space[1]}) !important;
    }

    &.gc-l .layout-child {
      flex: 0 0 calc(50% - ${theme.space[2]}) !important;
    }
  }

  .maybe-link-wrapper {
    flex-direction: row;
  }

  .layout-child {
    padding: ${theme.space[2]};
  }
`;

export const verticalCardGridCSS = css`
  display: flex;

  .vertical-card {
    width: 100%;
    margin-top: ${theme.space[1]};
  }
  .maybe-link-wrapper {
    display: flex;
    flex-direction: column-reverse;
    text-decoration: none;
    width: 100%;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: ${theme.colors.blueDark};
    }
  }

  p {
    margin-top: ${theme.space[2]};
  }

  h1,
  h2,
  h3 {
    line-height: 2rem;
  }

  &__content {
    &--left {
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
    }

    &--right {
      justify-content: flex-end;
      align-items: flex-end;
      text-align: right;
    }

    &--transparent {
      padding: ${theme.space[4]} 0;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.space[3]} ${theme.space[6]};
  }

  .button-wrapper {
    margin-top: ${theme.space[2]};

    .pretend-button {
      padding: 1em 2.825em;
      background: ${theme.colors.primary};
      font-size: 0.875em;
      font-weight: ${theme.fontWeights.medium};
      letter-spacing: 1px;
      color: ${theme.colors.white};
      text-align: center;
    }
    a,
    button,
    .pretend-button {
      background: ${theme.colors.blueDark};
      color: ${theme.colors.white};
      text-decoration: none;
      width: fit-content;
      text-transform: none;

      &:hover {
        background: ${theme.colors.blueDarkHover};
        transition: background-color 0.2s ease-in-out;
      }
    }
  }

  @media (min-width: 800px) and (max-width: 1250px) {
    .content {
      padding: ${theme.space[2]} ${theme.space[4]} !important;
    }

    .button-wrapper {
      width: 70% !important;
    }

    .image-wrapper {
      padding-top: 0 !important;
      img {
        max-height: 270px !important;
      }
    }

    /* &.gc-none .layout-child,
    &.gc-s .layout-child,
    &.gc-m .layout-child,
    &.gc-l .layout-child {
      flex: 0 0 100% !important;
    } */
  }

  ${theme.above.md} {
    .vertical-card {
      margin-top: ${theme.space[2]};
      /* padding: ${theme.space[2]}; */
      min-height: 270px;
      max-height: 270px;
    }

    .maybe-link-wrapper {
      flex-direction: row !important;
  
    }

    .content {
      flex: 0 0 60%;
      padding: ${theme.space[1]} ${theme.space[2]};
    }

    .image-wrapper {
      flex: 0 0 40%;
    }

    [data-flight-image-container] {
      height: 100% !important;
    }

    .button-wrapper {
      margin-top: ${theme.space[0]};
      width: 100%;

      a,
      button {
        width: 70%;
      }

      .pretend-button {
        width: 70%;
      }
    }

    @media (min-width: 1250px) {
      p {
        overflow: hidden;
        width: 310px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
`;

const base = verticalCardGridCSS;

const MaybeLinkWrapper = ({ children, className, link, clickableImage }) => {
  return React.createElement(
    clickableImage && link ? Link : 'div',
    {
      className,
      ...(clickableImage && link ? { to: link } : {})
    },
    children
  );
};

const VerticalCardGrid = props => {
  const {
    imageDesktop,
    imageMobile,
    aspectMobile,
    html,
    link,
    linkText,
    clickableImage
  } = getPropsOrFieldValues(props);

  if (!imageDesktop) return null;

  return (
    <div className={cx(base, 'vertical-card', 'maybe-link-wrapper')}>
      {/* <MaybeLinkWrapper
        className={'maybe-link-wrapper'}
        link={link}
        clickableImage={clickableImage}
      > */}

      <Below breakpoint="md">
        {matches => (
          <>
            <div className={cx(`${base}__content`, 'content')}>
              {html && (
                <div
                  className={cx(`${base}__html`, 'html')}
                  dangerouslySetInnerHTML={{
                    __html: html
                  }}
                />
              )}

              <div className={cx(`${base}__button-wrapper`, 'button-wrapper')}>
                {!clickableImage ? (
                  <TrendLink className={`${base}__button`} to={link}>
                    {linkText ? linkText : t('Read more')}
                  </TrendLink>
                ) : (
                  <TrendLink className={'pretend-button'} to={link}>
                    {linkText ? linkText : t('Read more')}
                  </TrendLink>
                )}
              </div>
            </div>

            <div className={cx(`${base}__image-wrapper`, 'image-wrapper')}>
              <MaybeLinkWrapper link={link} clickableImage={clickableImage}>
                <Image
                  className={cx(`${base}__image`, 'image')}
                  cover
                  src={matches ? imageMobile ?? imageDesktop : imageDesktop}
                  aspect={matches ? aspectMobile ?? '1:1' : '1:1'}
                />
              </MaybeLinkWrapper>
            </div>
          </>
        )}
      </Below>

      {/* </MaybeLinkWrapper> */}
    </div>
  );
};

export default VerticalCardGrid;
export { VerticalCardGrid };
