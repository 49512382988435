import React from 'react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image/Image';
import { Below } from '@jetshop/ui/Breakpoints';
import { theme } from '../../../Theme';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import HTML from './HTML';

const cardCSS = css`
  ${theme.above.md} {
    display: flex;
    position: relative;

    &__content {
      width: 100%;
      position: absolute;
      top: 50%;

      &--center {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }

      &--left {
        left: 0;
        transform: translateY(-50%);
        text-align: left;
      }

      &--right {
        right: 0;
        transform: translateY(-50%);
        text-align: right;
      }
    }

    div[data-flight-image-children] {
      position: absolute !important;
    }

    &__button-wrapper {
      margin-top: 0;
    }

    &__button {
      background: transparent;
      border: 1px solid ${theme.colors.white};
      color: ${theme.colors.white};
      text-decoration: none;
      text-align: center;

      &:hover {
        color: ${theme.colors.black};
        background: ${theme.colors.white};
        border: 1px solid ${theme.colors.white};
      }
    }
  }

  ${theme.below.md} {
    &__button {
      width: 100%;
      justify-content: center;
      background: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.white};
      text-decoration: none;

      &:hover {
        background: ${theme.colors.blueDarkHover};
        border: 1px solid ${theme.colors.blueDarkHover};
      }
    }
  }
`;

const base = cardCSS;

const MaybeLinkWrapper = ({ children, className, link, clickableImage }) => {
  return React.createElement(
    clickableImage && link ? Link : 'div',
    {
      className,
      ...(clickableImage && link ? { to: link } : {})
    },
    children
  );
};

const Card = props => {
  const {
    imageDesktop,
    aspectDesktop = '16:9',
    imageMobile,
    aspectMobile,
    contentPosition = 'center',
    preTitle,
    html,
    link,
    linkText,
    clickableImage,
    critical,
    children,
    imageCaption
  } = getPropsOrFieldValues(props);

  if (!imageDesktop) return null;

  return (
    <div>
      <MaybeLinkWrapper
        link={link}
        clickableImage={clickableImage}
        className={cx(base, 'card')}
      >
        <Below breakpoint="md">
          {matches =>
            matches ? (
              <>
                <HTML
                  className={base}
                  children={children}
                  preTitle={preTitle}
                  html={html}
                  link={link}
                  linkText={linkText}
                  clickableImage={clickableImage}
                  contentPosition={contentPosition}
                />
              </>
            ) : (
              <Image
                cover
                src={matches ? imageMobile ?? imageDesktop : imageDesktop}
                aspect={matches ? aspectMobile ?? aspectDesktop : aspectDesktop}
                critical={critical}
              >
                <>
                  <HTML
                    className={base}
                    children={children}
                    preTitle={preTitle}
                    html={html}
                    link={link}
                    linkText={linkText}
                    clickableImage={clickableImage}
                    contentPosition={contentPosition}
                  />
                </>
              </Image>
            )
          }
        </Below>
      </MaybeLinkWrapper>
      {imageCaption && (
        <div dangerouslySetInnerHTML={{ __html: imageCaption }} />
      )}
    </div>
  );
};

export default Card;
export { Card };
