import React from 'react';
import { css, cx } from 'linaria';
import createClassNames from '../../Utils/createClassNames';

const HTMLcss = css`
  &-row {
    display: flex;
    max-width: 1088px;
    flex-wrap: wrap;
    margin-left: -12px;
    width: calc(100% + 24px);
    padding-bottom: 32px;

    @media screen and (min-width: 1056px) {
      width: calc(100% + 64px);
      margin: 0 auto 0 auto;
      padding-bottom: 64px;
    }
    
    [data-flight-image-container] {
      padding 0 !important;
    }
    
  }

`;

const base = HTMLcss;
const ImageColumns = (props) => {
  const {
    className,
    children
  } = props;

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { count: children?.length });
    }
    return child;
  });

  return (
    <div className={cx(
      createClassNames([base, className], '-row'),
      'start'
    )} style={{maxWidth: `${children?.length >= 3 ? '1024px' : '1088px'}`}}>
      {childrenWithProps}
    </div>
  );
};

export default ImageColumns;
export { ImageColumns };
