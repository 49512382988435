import React from 'react';
import { css, cx } from 'linaria';
import Image from '@jetshop/ui/Image/Image';
import { Below } from '@jetshop/ui/Breakpoints';
import HTML from './HTML';
import { theme } from '../../../Theme';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';

const sideBySideCSS = css`
  flex-direction: column;
  color: ${theme.colors.primary};
  flex-wrap: wrap;

  &__content,
  &__image-wrapper {
    flex: 0 0 50%;
    flex-wrap: wrap;
  }

  &__button-wrapper {
    padding-top: ${theme.space[3]};
  }

  &__button {
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    font-size: 0.875em;
    font-weight: ${theme.fontWeights.medium};
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    transition-property: background-color, border-color, color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    &:hover {
      background-color: ${theme.colors.primaryHover};
      border-color: ${theme.colors.primaryHover};
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${theme.space[4]} 0 0;
    text-align: center;

    ${theme.below.md} {
      padding: ${theme.space[4]} 1rem 1rem;
    }

    p {
      padding-top: ${theme.space[1]};
    }

    &--left {
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
    }

    &--right {
      justify-content: flex-end;
      align-items: flex-end;
      text-align: right;
    }

    &--transparent {
      padding: ${theme.space[4]} 0;
    }
  }

  ${theme.above.md} {
    padding: 0;
    &__button-wrapper {
      padding-top: ${theme.space[0]};
    }
  }

  ${theme.below.md} {
    &__html {
      h1,
      h2,
      h3 {
        font-size: 1.75rem !important;
      }
    }
  }
`;

const base = sideBySideCSS;

const SideBySide = props => {
  const {
    imageDesktop,
    aspectDesktop = '16:9',
    imageMobile,
    aspectMobile,
    contentPosition = 'left',
    html,
    link,
    linkText,
    reverse,
    critical,
    children
  } = getPropsOrFieldValues(props);

  if (!imageDesktop) return null;

  return (
    <div
      link={link}
      className={cx(base, reverse ? `${base}--reverse` : null, 'side-by-side')}
    >
      <Below breakpoint="md">
        {matches => (
          <>
            <div className={`${base}__image-wrapper`}>
              <Image
                cover
                src={matches ? imageMobile ?? imageDesktop : imageDesktop}
                aspect={matches ? aspectMobile ?? aspectDesktop : aspectDesktop}
                critical={critical}
                sizes={[1, 1, 1, 1, 625]}
              />
            </div>
            <HTML
              className={base}
              children={children}
              contentPosition={contentPosition}
              html={html}
              link={link}
              linkText={linkText}
            />
          </>
        )}
      </Below>
    </div>
  );
};

export default SideBySide;
export { SideBySide };
