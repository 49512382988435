import React, { useState } from 'react';
import { css, cx } from 'linaria';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import createClassNames from '../../Utils/createClassNames';
import { styled } from 'linaria/react';
import { ReactComponent as Chevron } from '../../../../svg/IconChevron.svg';
import { AccordionContent } from '@jetshop/ui/Accordion/Accordion';
import { theme } from '../../../Theme';

const HTMLcss = css`
  &-wrapper {
    margin-bottom: 16px;
  }

  &-header {
    transition: background-color 0.2s linear;
    background-color: #f0f0f0;
    padding: 16px 40px 16px 16px;
    width: 100%;
    text-align: center;
    position: relative;

    p {
      font-size: 14px;
      font-weight: 500;
      
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }

    svg {
      position: absolute;
      right: 24px;
      top: 20px;
    }

    &-open {
      background-color: #dbdbdb;

      svg {
        transform: rotate(90deg);
      }
    }
  }

  &-body {
    background-color: #f0f0f0;
    padding: 8px 16px;
    width: 100%;
    text-align: center;
    
    @media screen and (min-width: 768px) {
      padding: 24px;
    }
  }
`;

const ChevronIcon = styled(Chevron)`
  height: 14px;
  width: 8px;
  transform: rotateZ(-90deg);
  stroke: ${theme.colors.primary};
  transition: transform 200ms;

  &.is-open {
    transform: rotate(90deg);
  }
`;

const base = HTMLcss;
const FAQItem = (props) => {
  const {
    className,
    title,
    text
  } = getPropsOrFieldValues(props);
  const [open, setOpen] = useState(false);

  return (
    <div className={cx(
      createClassNames([base, className], `-wrapper`)
    )}>
      <button onClick={() => setOpen(!open)} className={cx(
        createClassNames([base, className], `-header`),
        open && createClassNames([base, className], `-header-open`)
      )}>
        <p>{title}</p>
        <ChevronIcon />
      </button>
      <AccordionContent isOpen={open}>
        <div className={cx(
          createClassNames([base, className], `-body`)
        )}>
          {text && (
            <div
              className={cx(
                createClassNames([base, className], '__text')
              )}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </div>
      </AccordionContent>
    </div>
  );
};

export default FAQItem;
export { FAQItem };
