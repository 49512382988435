import React from 'react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image/Image';
import { Below } from '@jetshop/ui/Breakpoints';
import { getPropsOrFieldValues } from '../../Utils/getPropsOrFieldValues';
import { theme } from '../../../Theme';
import HTML from './HTML';

const heroCSS = css`
  display: flex;
  position: relative;
  color: ${theme.colors.white};

  &__content {
    width: 100%;
    position: absolute;

    &--center-center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    &--center-bottom {
      bottom: ${theme.space[2]};
      text-align: center;
    }
  }

  &__pre-title {
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  &__content--cursive &__pre-title {
    font-family: ${theme.fonts.secondary};
    font-size: 6rem;
    text-transform: none;
  }

  &__button {
    margin-top: ${theme.space[2]};

    text-decoration: none;
  }

  &__button-wrapper {
    padding: 0 ${theme.space[2]};
  }

  &__html {
    max-width: 90%;
    margin: 0 auto;
    h1,
    h2,
    h3,
    p {
      margin-top: ${theme.space[0]};
      font-weight: 500;
      font-size: 1.625rem;
      line-height: 1.8rem;
    }
  }

  div[data-flight-image-children] {
    position: absolute !important;
  }

  ${theme.above.md} {
    &__content {
      &--center-bottom {
        bottom: ${theme.space[6]};
      }
    }

    &__pre-title {
      font-size: 1.2rem;
    }

    &__html {
      h1,
      h2,
      h3,
      p {
        font-size: 2.5rem;
        font-weight: 500;
        margin-top: 6px;
      }
    }
  }

  ${theme.below.md} {
    h1,
    h2,
    h3 {
      /* font-size: 2.375rem !important; */
    }

    &__content--cursive &__pre-title {
      font-size: 3rem;
      margin-bottom: ${theme.space[2]};
    }
  }
`;

const base = heroCSS;

const MaybeLinkWrapper = ({ children, className, link, clickableImage }) => {
  return React.createElement(
    clickableImage && link ? Link : 'div',
    {
      className,
      ...(clickableImage && link ? { to: link } : {})
    },
    children
  );
};

const Hero = props => {
  const {
    imageDesktop,
    aspectDesktop = '16:9',
    imageMobile,
    aspectMobile,
    contentPosition = 'center-center',
    font,
    preTitle,
    html,
    link,
    linkText,
    clickableImage,
    critical,
    children,
    imageCaption
  } = getPropsOrFieldValues(props);

  if (!imageDesktop) return null;

  return (
    <div>
      <MaybeLinkWrapper
        link={link}
        clickableImage={clickableImage}
        className={cx(base, 'hero')}
      >
        <Below breakpoint="md">
          {matches => (
            <Image
              cover
              src={matches ? imageMobile ?? imageDesktop : imageDesktop}
              aspect={matches ? aspectMobile ?? aspectDesktop : aspectDesktop}
              critical={critical}
              sizes={[1, 1, 1, 1, 1280]}
            >
              <>
                <HTML
                  className={base}
                  children={children}
                  preTitle={preTitle}
                  html={html}
                  link={link}
                  linkText={linkText}
                  clickableImage={clickableImage}
                  contentPosition={contentPosition}
                  font={font}
                />
              </>
            </Image>
          )}
        </Below>
      </MaybeLinkWrapper>
      {imageCaption && (
        <div dangerouslySetInnerHTML={{ __html: imageCaption }} />
      )}
    </div>
  );
};

export default Hero;
export { Hero };
