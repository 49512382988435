import React from 'react';
import { css, cx } from 'linaria';
import createClassNames from '../../Utils/createClassNames';

const HTMLcss = css`
  &-wrapper {
    max-width: 1024px;
    padding: 42px 16px;
    margin: 0 auto 0px;

    @media screen and (min-width: 1056px) {
      padding: 42px 0 0;
      padding-bottom: 64px;
    }
  }

  &-title {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem !important;
    color: #000;
    font-weight: 500;
  }
`;

const base = HTMLcss;
const FAQ = (props) => {
  const {
    className,
    title,
    children
  } = props;

  return (
    <div className={cx(
      createClassNames([base, className], '-wrapper'),
      'start'
    )}>
      {title.value && title.value !== "" && (
        <p className={cx(
          createClassNames([base, className], '-title'),
          'start'
        )}>{title.value}</p>
      )}
      {children}
    </div>
  );
};

export default FAQ;
export { FAQ };
