import React from 'react';
import { css, cx } from 'linaria';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import createClassNames from '../../Utils/createClassNames';

const HTMLcss = css`
  &-rich-text {
    max-width: 1024px;
    margin: 0 auto 0;
    padding: 16px;

    // @media screen and (min-width: 1056px) {
    //   padding: 42px 0 0;
    // }

    @media screen and (min-width: 768px) {
      padding-bottom: 32px;
    }

    @media screen and (min-width: 1056px) {
      padding-bottom: 64px;
    }
  }

  &-title {
    font-size: 1.75rem;
    margin-bottom: 0.75rem !important;
    line-height: 2.25rem;
    font-weight: 500;
  }
`;

const base = HTMLcss;
const RichText = (props) => {
  const {
    className,
    title,
    content
  } = getPropsOrFieldValues(props);

  return (
    <div className={cx(
      createClassNames([base, className], '-rich-text'),
      'start'
    )}>
      {title && title !== " " && (
        <p className={cx(
          createClassNames([base, className], '-title'),
          'start'
        )}>{title}</p>
      )}
      <div dangerouslySetInnerHTML={{__html: content}}>
      </div>
    </div>
  );
};

export default RichText;
export { RichText };
