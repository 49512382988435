import React from 'react';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import createClassNames from '../../Utils/createClassNames';
import Image from '@jetshop/ui/Image/Image';
import { Below } from '@jetshop/ui/Breakpoints';
import { Link } from 'react-router-dom';
import { maxWidth } from '../../ThemeClasses';
import { ReactComponent as Chevron } from '../../../../svg/IconChevron.svg';

const HTMLcss = css`
  &-hero {
    max-height: 460px;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 32px;

    &:before {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding-bottom: 11%;
      background: -moz-linear-gradient(top, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 100%);
      background: -webkit-linear-gradient(top, rgba(0,0,0,0.3) 0%,rgba(255,255,255,0) 100%);
      background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(255,255,255,0) 100%);
      z-index: 5;
    }
    
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0,0,0,.15);
      transition: background .2s ease-in-out;
    }
    
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 460px;
      z-index: 400;
      position: relative;
    }

    &__back {
      z-index: 401;
      position: absolute;
      margin-top: 42px;

      &-link {
        color: #fff;
        text-shadow: 0 0 19px rgba(0, 0, 0, 0.5);
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;
        margin-left: 24px;
        font-size: .75rem;
        line-height: 1.375rem;
        font-weight: 300;

        &:hover svg {
          transform: translate(-6px);
        }
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 1.5rem;
    color: #fff;
    text-shadow: 0 0 19px rgba(0, 0, 0, 0.5);

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }

  &-image {
    position: absolute !important;
    pointer-events: none;
    height: 100% !important;

    img {
      max-height: 460px;
    }
  }
`;

const LinkChevron = styled(Chevron)`
  height: 12px;
  width: 5px;
  margin-top: -1px;
  transform: rotateZ(0deg);
  stroke: #fff;
  margin-right: 6px;
  transition: transform 0.2s linear;

  &.is-open {
    transform: rotate(90deg);
  }
`;

const base = HTMLcss;
const HeroCategory = (props) => {
  const {
    className,
    title,
    desktopImage,
    phoneImage,
    buttonLink,
    buttonText
  } = getPropsOrFieldValues(props);

  return (
    <div className={cx(
      createClassNames([base, className], '-hero'),
    )}>
      <Below breakpoint='md'>
        {matches => (
          <Image
            cover
            className={cx(
              createClassNames([base, className], '-image')
            )}
            src={matches ? phoneImage ?? desktopImage : desktopImage}
            critical={true}
            sizes={[1, 1, 1, 1, 1280]}
          >
          </Image>
        )}
      </Below>
      <div className={cx(maxWidth)}>
        <div className={cx(
          createClassNames([base, className], '-hero__back'),
        )}>
          <Link to={buttonLink || '/'} aria-label='Smycka' className={cx(
            createClassNames([base, className], '-hero__back-link')
          )}>
            <LinkChevron />
            {buttonText}
          </Link>
        </div>
        <div className={cx(
          createClassNames([base, className], '-hero__content')
        )}>
          <h1 className={cx(
            createClassNames([base, className], '__title')
          )}>{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default HeroCategory;
export { HeroCategory };
